<template>
  <div class="bulkComm-detail">
    <div class="banner">
      <div class="type">
        <div class="title">
          <div class="iocn"></div>
          <span class="order">单号：</span>
          <span class="orderNum">{{ requreCarData.carCode }}</span>
        </div>
        <div class="details">
          <div class="left">
            <p>
              车主：<span class="personal">{{ requreCarData.carOwner }}</span>
            </p>
            <p>
              提交申请时间：<span class="p-time">{{
                requreCarData.creationTime
              }}</span>
            </p>
            <p>
              车主备注：<span class="p-remakes">{{
                requreCarData.remark
              }}</span>
            </p>
          </div>
          <div class="right">
            <p>
              审核人：<span class="examine">{{
                requreCarData.reviewedUser
              }}</span>
            </p>
            <p>
              审核时间：<span class="e-time">{{
                requreCarData.reviewedTime
              }}</span>
            </p>
            <p>
              审核备注：<span class="e-remakes">{{
                requreCarData.content
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="process">
        <div class="title">
          <div class="icon"></div>
          <span>流程进度</span>
        </div>
        <div class="progress">
          <ul>
            <li :class="`progress-item ${item.status}${Number(item.value) + 1}`"
              v-for="item in orderStatus.customerStatusList" :key="item.value">
              <div class="progress-img" />
              <span :class="`${item.status}Text`">{{ item.text }}</span>
              <span :class="`examine ${item.status}examine`">{{
                item.name
              }}</span>
              <span :class="`e-time ${item.status}time`">{{ item.time }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="owner">
        <div class="title">
          <div class="icon"></div>
          <span>车主信息</span>
        </div>
        <div class="information">
          <ul>
            <li>
              <span>车主：</span><em class="personal">{{ requreCarData.carOwner }}</em>
            </li>
            <li>
              <span>车辆型号：</span><em class="model">{{ requreCarData.carType }}</em>
            </li>
            <li>
              <span>联系电话：</span><em class="tel">{{ requreCarData.phone }} </em>
            </li>
            <li>
              <span>联系地址：</span><em class="address">{{ requreCarData.address }}</em>
            </li>
          </ul>
        </div>
      </div>
      <div class="image">
        <div class="title">
          <div class="icon"></div>
          <span>图片信息</span>
        </div>
        <div class="information" ref="information">
          <ul>
            <li v-for="item in requreCarData.photos" :key="item.fileId">
              <img :src="item.path" alt="" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

export default {
  name: "fillInTheOrder",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      orderStatus: (state) => {
        let orderStatus = state.persoCenScrapDetail.orderStatus;
        let customerStatus = orderStatus.customerStatus;
        if (
          orderStatus.customerStatusList &&
          orderStatus.customerStatusList.length > 0
        ) {
          orderStatus.customerStatusList = orderStatus.customerStatusList.map(
            (item, index) => {
              customerStatus < index && (item.status = "noApproval"); // 未审批
              customerStatus === index && (item.status = "ongoing"); // 审批中
              customerStatus > index && (item.status = "haveApproval"); // 审批完成
              return item;
            }
          );
        }
        return orderStatus;
      },
      requreCarData: (state) => {
        return state.persoCenScrapDetail.requreCarData;
      },
    }),
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("persoCenScrapDetail/getOrderStatus", {
        ...this.$route.query,
      });
      this.$store.dispatch("persoCenScrapDetail/getRequreCar", {
        ...this.$route.query,
      }).then(() => {
				const ViewerDom = this.$refs.information;
				const viewer = new Viewer(ViewerDom, {
					// inline: true,
					title: false,
					fullscreen: false,
					// zoomRatio: 3,
					// zIndexInline: 10,
					// url: 'data-original',
					// viewed () {
					//	viewer.zoomTo(0.6)
					//}
				})
			})
    },
  },
};
</script>

<style lang="less" scoped>
em {
  font-style: normal;
}
.clearFix() {
  *zoom: 1;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.banner {
  // position: relative;
  height: 200px; /* no */
  background-image: url(/img/scrapDetails/banner.png);
  background-size: 100%;
  opacity: 0.8;
  .type {
    width: 1200px; /* no */
    height: 100%;
    margin: 0 auto;
    .title {
      width: 100%;
      .clearFix();
      .iocn {
        width: 40px; /* no */
        height: 40px; /* no */
        background: url(/img/scrapDetails/icon.png) no-repeat;
        background-size: 100%;
        margin-top: 29px; /* no */
        float: left;
        box-shadow: 0px 2px 11px 0px rgba(41, 119, 210, 0.4); /* no */
      }
      .order {
        height: 22px; /* no */
        float: left;
        margin-left: 24px; /* no */
        margin-top: 35px; /* no */
        font-size: 22px; /* no */
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #ffffff;
      }
      .orderNum {
        height: 21px; /* no */
        float: left;
        margin-top: 35px; /* no */
        letter-spacing: 1px; /* no */
        font-size: 24px; /* no */
        font-family: OPPOSans;
        font-weight: normal;
        color: #ffffff;
      }
    }
    .details {
      > div {
        margin-top: 19px; /* no */
        > p {
          font-size: 16px; /* no */
          font-family: SourceHanSansCN;
          font-weight: 300;
          color: #b9dbf7;
          margin-bottom: 10px; /* no */
          > span {
            font-size: 16px; /* no */
            font-family: SourceHanSansCN;
            font-weight: 300;
            color: #ffffff;
          }
        }
      }
      .left {
        margin-left: 62px; /* no */
        float: left;
      }
      .right {
        float: left;
        margin-left: 237px; /* no */
      }
    }
  }
}
.content {
  width: 1200px; /*no*/
  margin: 30px auto 42px; /* no */
  border: 2px solid #ffffff; /* no */
  box-shadow: 0px 0px 16px 0px rgba(68, 109, 194, 0.2); /* no */
  border-radius: 5px; /* no */
  background: #ffffff;
  .process {
    width: 100%;
    background: linear-gradient(90deg, #cee9ff 0%, #edf8fd 100%);
    border-radius: 5px; /* no */
    padding-bottom: 18px; /* no */
    .clearFix();
    .title {
      width: 100%;
      height: 70px; /* no */
      border-bottom: 1px solid #ffffff; /* no */
      .clearFix();
      .icon {
        width: 46px; /* no */
        height: 46px; /* no */
        background: url(/img/scrapDetails/content.png) no-repeat left;
        background-size: 46px; /* no */
        margin: 14px 0 0 23px; /* no */
        float: left;
      }
      > span {
        line-height: 70px; /* no */
        font-size: 20px; /* no */
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #333333;
        margin-left: 10px; /* no */
        float: left;
      }
    }
    .progress {
      width: 100%;
      > ul {
        width: 100%;
        margin-left: 27px; /* no */
        padding-top: 29px; /* no */
        overflow: hidden;
        > .progress-item {
          position: relative;
          padding-right: 3.5%;
          width: 8.9%;
          // height: 139px;
          float: left;
          img {
            margin-left: 14px; /* no */
            display: block;
          }
          &:after {
            content: "";
            width: 36px; /* no */
            height: 50px; /* no */
            background-image: url("/img/scrapDetails/9.png");
            position: absolute;
            right: 6px; /* no */
            top: 30px; /* no */
          }
          .progress-img {
            width: 80%;
            margin: 0 auto;
            height: 89px; /*no*/
            background-image: url("/img/scrapDetails/orderStatus.png");
            background-repeat: no-repeat;
          }
          &.haveApproval1 {
            .progress-img {
              background-position: -37px -20px; /*no*/
            }
          }
          &.haveApproval2 {
            .progress-img {
              background-position: -187px -20px; /*no*/
            }
          }
          &.haveApproval3 {
            .progress-img {
              background-position: -338px -20px; /*no*/
            }
          }
          &.haveApproval4 {
            .progress-img {
              background-position: -484px -20px; /*no*/
            }
          }
          &.haveApproval5 {
            .progress-img {
              background-position: -633px -20px; /*no*/
            }
          }
          &.haveApproval6 {
            .progress-img {
              background-position: -782px -20px; /*no*/
            }
          }
          &.haveApproval7 {
            .progress-img {
              background-position: -931px -20px; /*no*/
            }
          }
          &.haveApproval8 {
            .progress-img {
              background-position: -1079px -20px; /*no*/
            }
          }
          &.ongoing1 {
            .progress-img {
              background-position: -37px -180px; /*no*/
            }
          }
          &.ongoing2 {
            .progress-img {
              background-position: -187px -180px; /*no*/
            }
          }
          &.ongoing3 {
            .progress-img {
              background-position: -338px -180px; /*no*/
            }
          }
          &.ongoing4 {
            .progress-img {
              background-position: -489px -180px; /*no*/
            }
          }
          &.ongoing5 {
            .progress-img {
              background-position: -633px -180px; /*no*/
            }
          }
          &.ongoing6 {
            .progress-img {
              background-position: -782px -180px; /*no*/
            }
          }
          &.ongoing7 {
            .progress-img {
              background-position: -931px -180px; /*no*/
            }
          }
          &.ongoing8 {
            .progress-img {
              background-position: -1079px -180px; /*no*/
            }
          }
          &.noApproval1 {
            .progress-img {
              background-position: -37px -339px; /*no*/
            }
          }
          &.noApproval2 {
            .progress-img {
              background-position: -187px -339px; /*no*/
            }
          }
          &.noApproval3 {
            .progress-img {
              background-position: -338px -339px; /*no*/
            }
          }
          &.noApproval4 {
            .progress-img {
              background-position: -490px -339px; /*no*/
            }
          }
          &.noApproval5 {
            .progress-img {
              background-position: -633px -339px; /*no*/
            }
          }
          &.noApproval6 {
            .progress-img {
              background-position: -782px -339px; /*no*/
            }
          }
          &.noApproval7 {
            .progress-img {
              background-position: -931px -339px; /*no*/
            }
          }
          &.noApproval8 {
            .progress-img {
              background-position: -1079px -339px; /*no*/
            }
          }
          &:last-child {
            padding-right: 0;
          }
          &:last-child:after {
            content: "";
            display: none;
          }
          > span {
            display: block;
            text-align: center;
            font-size: 16px; /* no */
            font-family: SourceHanSansCN;
            font-weight: 700;
            color: #333333;
          }
          .examine {
            margin-top: 10px; /* no */
          }
          .examine,
          .e-time {
            font-size: 14px; /* no */
            font-family: SourceHanSansCN;
            font-weight: 300;
            color: #879baa;
          }
          .haveApprovalText {
            color: #333333;
          }
          .ongoingText,
          .ongoingtime,
          .ongoingexamine {
            color: #30af7d;
          }
          .noApprovalText,
          .noApprovaltime,
          .noApprovalexamine,
          .haveApprovaltime,
          .haveApprovalexamine {
            color: #879baa;
          }
          // &.arrow {
          //   width: 2.689%;
          //   height: 50px;
          //   margin: 14px 1px 0 10px;
          //   img {
          //     margin-left: 0;
          //   }
          // }
          .active {
            color: #30af7d;
          }
        }
      }
    }
  }
  .owner {
    height: 170px; /* no */
    background: rgba(245, 249, 252, 0.9);
    .title {
      width: 100%;
      height: 70px; /* no */
      background: #fff;
      border-bottom: 1px solid #ffffff; /* no */
      .clearFix();
      .icon {
        width: 46px; /* no */
        height: 46px; /* no */
        background: url(/img/scrapDetails/content.png) no-repeat left;
        background-size: 46px; /* no */
        margin: 14px 0 0 23px; /* no */
        float: left;
      }
      > span {
        line-height: 70px; /* no */
        font-size: 20px; /* no */
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #333333;
        margin-left: 10px; /* no */
        float: left;
      }
    }
    .information {
      width: 100%;

      ul {
        overflow: hidden;
        margin-left: 58px; /* no */
        li {
          float: left;
          width: 500px; /* no */
          line-height: 20px; /* no */
          margin-top: 20px; /* no */
          background: url(/img/scrapDetails/spot.png) no-repeat no-repeat left
            4px; /* no */
          background-size: 12px; /* no */
          > span {
            float: left;
            font-size: 16px; /* no */
            padding-left: 20px; /* no */
            font-family: SourceHanSansCN;
            font-weight: 300;
            color: #333333;
          }
          > em {
            float: left;
            font-size: 16px; /* no */
            font-family: SourceHanSansCN;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }
  }
  .image {
    .title {
      width: 100%;
      height: 70px; /* no */
      background: #fff;
      border-bottom: 1px solid #ffffff; /* no */
      .clearFix();
      .icon {
        width: 46px; /* no */
        height: 46px; /* no */
        background: url(/img/scrapDetails/content.png) no-repeat left;
        background-size: 46px; /* no */
        margin: 14px 0 0 23px; /* no */
        float: left;
      }
      > span {
        line-height: 70px; /* no */
        font-size: 20px; /* no */
        font-family: SourceHanSansCN;
        font-weight: 300;
        color: #333333;
        margin-left: 10px; /* no */
        float: left;
      }
    }
    .information {
      background-color: rgba(245, 249, 252, 0.9);
      width: 100%;
      overflow: hidden;
      ul {
        li {
          width: 21.8%;
          float: left;
          margin: 1.62% 0 0 2.5%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
